import React, { useState } from 'react';
import { Link } from "react-router-dom";
import StationContent from "./StationContent.json";
import loom1 from "./assets/1209_Backstrap_1K.glb";
import loom2 from "./assets/1124_WarpWeighted_1K.glb";
import loom3 from "./assets/1124_tapestry_2K.glb";
import loom4 from "./assets/1124_foot_1K.glb";
import loom5 from "./assets/1124_jac_1K.glb";
import loom6 from "./assets/punchcard-solo.glb";
import loom7 from "./assets/1124_Punchcard_1K.glb";


const LOOM_ASSETS = {
    "humansasmachines": [loom1, loom2, loom3, loom4, loom5],
    "humansautomatingmachines": [loom5, loom6, loom7]
}

const SHADOW_INT = {
    "humansasmachines": ["2", "2", "3", "2.5", "2"],
    "humansautomatingmachines": ["2", "0", "2"]
}

const EXPOSURE = {
    "humansasmachines": ["1.2", "1.5", "1.4", "1.6", "1"],
    "humansautomatingmachines": ["1", "0.7", "1"]
}

const CAM_ORB = {
    "humansasmachines": ["15deg 75deg 3.58m", "15deg 75deg 3.59m", "15deg 75deg 3.5m", "15deg 75deg 3.52m", "15deg 75deg 3.6m"],
    "humansautomatingmachines": ["15deg 75deg 3.6m", "15deg 75deg 1.5m", "15deg 75deg 3.4m"]
}

const MAX_CAM_ORB = {
  "humansasmachines": ["1115deg 75deg 3.58m", "1115deg 75deg 3.59m", "1115deg 75deg 3.5m", "1115deg 75deg 3.52m", "1115deg 75deg 3.6m"],
  "humansautomatingmachines": ["1115deg 75deg 3.6m", "1115deg 75deg 3.5m", "1115deg 75deg 3.4m"]
}



export function ExhibitNav(props) {
    const name = props.name
    console.log("Exhibit nav props ", props)


    setTimeout(function () {

        try {


        const modelViewer = document.querySelector('#toggle-model');
        modelViewer.dismissPoster()


    } catch (e) {
        console.log(e)
    }




    }, 300)



    let detailPage = props.detailPage
    let currentUrl = props.currentUrl


    const [currentPage, setPage] = useState(props.detailPage)

    const content = StationContent[name]
    let loom_data = content.loom_data
    let arUrl = `/svg/ar${content.number}.svg`


    console.log("Setting url for loom model")
    console.log(loom_data)

    console.log("Detail page ", detailPage)
    console.log("Current loom ", loom_data[detailPage])

    let currentModelURL = ""
    // console.log(currentPage);
    setTimeout(function(){ updateModelViewerAttributes(currentPage); }, 500);

    // Detail Page Refers to the current Index of Loom we are talking about
    function updateModelViewerAttributes(currentDetailPageNumber) {
        const toggleModel = document.querySelector('#toggle-model');
        const arModel = document.querySelector('#AR-model');

        console.log("Setting model url to ", loom_data[currentDetailPageNumber].modelUrl)
        console.log(toggleModel);
        console.log(arModel);

        try {

        

        // We are loading the GLB Directly from the assets folder directly.  When the page loads we also load all the GLBs into memory
        //
        toggleModel.setAttribute('src', `${LOOM_ASSETS[name][currentDetailPageNumber]}`)
        console.log("THE CURRENT MODEL URL IS ", currentModelURL)

        // This is the URL of the specific GLB.  So specifically 1124_tapestry_2k.glb
        currentModelURL = loom_data[currentDetailPageNumber].modelUrl

        // Model name with html extension
        let currentModelHtmlUrl = currentModelURL.replace("glb", "html");

        // toggleModel.setAttribute('src', `${LOOM_ASSETS[name][currentDetailPageNumber]}`)
        console.log("HTML File value : ", currentModelHtmlUrl)

        // Model name with usdz extension
        let currentModelUsdzUrl = currentModelURL.replace("glb", "usdz");
        // toggleModel.setAttribute('usdz', `${LOOM_ASSETS[name][currentDetailPageNumber]}`)
        console.log("USDZ File value : ", currentModelHtmlUrl)


        // Normal Name for Alt Tag
        let altTagValue = loom_data[currentPage].name
        // toggleModel.setAttribute('alt', altTagValue)
        console.log("Alt tag value ", altTagValue)

        console.log(name + " No. " + currentDetailPageNumber)
        arModel.setAttribute('src', `${LOOM_ASSETS[name][currentDetailPageNumber]}`)
        toggleModel.setAttribute('shadow-intensity', `${SHADOW_INT[name][currentDetailPageNumber]}`)
        toggleModel.setAttribute('exposure', `${EXPOSURE[name][currentDetailPageNumber]}`)

        arModel.setAttribute('ios-src', currentModelUsdzUrl)
        arModel.setAttribute('alt', altTagValue)
        toggleModel.setAttribute('camera-orbit', `${CAM_ORB[name][currentDetailPageNumber]}`)
        // toggleModel.setAttribute('min-camera-orbit', `auto auto auto`)
        toggleModel.setAttribute('max-camera-orbit', `${MAX_CAM_ORB[name][currentDetailPageNumber]}`)
        } catch (exception) {
            console.log(exception)
        }


    }


    function incrementPage() {
        console.log("loom data length ", loom_data.length)
        const newPageValue = (parseInt(currentPage) + 1) % loom_data.length;

        console.log((currentPage + 1) % loom_data.length)
        console.log((parseInt(currentPage) + 1) % loom_data.length)



        setPage(newPageValue)
        console.log(`Moving from current page ${currentPage} to the next page ${newPageValue}`)

        updateModelViewerAttributes(newPageValue)


    }

    function decrementPage() {
        let newPageValue = 0

        if (currentPage === 0) {
            newPageValue = Math.abs(loom_data.length - 1);
        } else {
            newPageValue = Math.abs((currentPage - 1) % loom_data.length);
        }

        setPage(newPageValue)

        updateModelViewerAttributes(newPageValue)


    }

    function openAR() {
        console.log("I am opening the ar model")
        document.getElementById('AR-model').activateAR()
    }



    return <div>
        <div className="exhibitNav">


            <img src="/svg/arrow/big.svg" onClick={decrementPage} className="arrowLeft" alt="Left Facing Arrow" />
            <img src="/svg/arrow/big.svg" onClick={incrementPage} className="arrowRight" alt="Right Facing Arrow" />


            <div className="exhibitNavDisplayContainer">
                <div className="modelInstructions">

                    <div className="modelInstructionsText">
                        <img className="buttonText modelInstructionsIcon" src="/svg/360.svg" alt="arrow point 360 degrees" />
                        <p>Click, drag and scroll to interact with object.</p>
                    </div>


                </div>
                <p className={"center-align buttonText exhibitNavHeader stationText" + content.number}>{loom_data[currentPage].name}</p>
                <div className="navButtonRow" >

                    <img src={arUrl} onClick={openAR} className={"ar-icon stationBorder" + content.number} alt="AR ICon" />
                    <div className={"exhibit-button-nav center-align stationBorder" + content.number + " stationText" + content.number}>

                        <Link className="buttonText" to={`${currentUrl}/${loom_data[currentPage].id}`}><p className="buttonText">LEARN MORE</p></Link>


                    </div>
                </div>



            </div>
        </div>



    </div>;
}
