import React from 'react';
import { NavBar } from './NavBar';

export default function Credits() {


  return (
    <div><div>
      <NavBar loomName={""} url={""} detailPageOpen={""} name="Exhibition Credits"></NavBar>

      <div className="creditsContainer">

        <br></br>

        <div className="credit">
          <p className="center-align creditsName">Exhibition by <span className="stationText2">Weaving X Coding</span> studio, through the Gottesman Libraries Myers Art Commission.</p>

        </div>
        <div className="">

        </div>

        <div className="credit">
          <p className="center-align creditsTitle">Concept, Content & Production</p>
          <p className="center-align creditsName">Francesca Rodriguez Sawaya & Renata de Carvalho Gaui</p>


        </div>

        <div className="credit">
          <p className="center-align creditsTitle">Full Stack Development</p>
          <p className="center-align creditsName">Matthew Ross</p>


        </div>
        <div className="credit">
          <p className="center-align creditsTitle">3D Artist</p>
          <p className="center-align creditsName">Mint Boonyapanachoti</p>
        </div>

        <div className="credit">
          <p className="center-align creditsTitle">Augmented Reality</p>
          <p className="center-align creditsName">Mithru Vigneshwara</p>


        </div>
        <div className="credit">
          <p className="center-align creditsTitle">Typography</p>
          <p className="center-align creditsName">Vinila by Estúdio Plau</p>


        </div>
        <div className="credit">
          <p className="center-align creditsTitle">Special Thanks to</p>
          <p className="center-align creditsName">
          Aaron Montoya-Moraga, Alex McLean, C Alex Clark, Emma Wood, Faig Ahmed, Frank da Cruz, Indira Allegra, Jennifer Govan, Julian Goldman, Kati Hyyppä, Lauren Lee McCarthy, Marcela Antipán, Maria Elisa Assy, Nathalie Gebert, Pamela Liou, Patrick Delaney, Shruti Shyam, Sonya Clark, Trisha Barton, Victoria Manganiello and Zoe Logan.              </p>
        </div>

      </div>
      <div className="creditsEndContainer">
        <div className="centeredContent">

          <img src="/svg/icon/short.svg" alt="Kiwi standing on oval" />

        </div>
        <div className="credit">
          <p style={{ maxWidth: "70vw" }} className="center-align stationText2">This exhibition is dedicated to Bita and Ivone.</p>
          <div> <a rel="noopener noreferrer" target="_blank" href="https://weavingxcoding.studio" className="center-align creditsTitle">https://weavingxcoding.studio</a></div>


        </div>
      </div>



    </div>  </div>
  );
}