import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import StationContent from "./StationContent.json";


export function ExhibitModal(props) {

 // eslint-disable-next-line
    const [modalIsOpen, setIsOpen] = useState(false);
    const [wasOpen, setWasOpen] = useState(false);


    // We Add this timer so that the modal comes up after the animation is completed
    useEffect(() => {
        const timer = setTimeout(() => {
            if (props.showModal) {
                props.onChange(true)
            }
       }, 600);
        return () => clearTimeout(timer);
      }, []);


    function afterOpenModal() {
        setTimeout(() => {
            setWasOpen(true);
        }, 0);
    }

    function afterClose() {
        setTimeout(() => {
            setWasOpen(false);
        }, 0);
    }
    const name = props.name

    const content = StationContent[name]
     // eslint-disable-next-line
    const [modalState, setModalState] = useState(false)

    function closeModal() {
        console.log("CLOSING ")
        props.onChange(false)
        setModalState(false)

        setIsOpen(false);

    }

    return (
        <div className="">
        <Modal
            isOpen={props.modelIsOpen}
            onRequestClose={() => setIsOpen(false)}
            overlayClassName={{
                base: "overlay-base",
                afterOpen: wasOpen && "overlay-after",
                beforeClose: "overlay-before"
            }}
            className={{
                base: "StationModal",
                afterOpen: wasOpen && "content-after",
                beforeClose: "content-before"
            }}
            closeTimeoutMS={1500}
            onAfterOpen={afterOpenModal}
            onAfterClose={afterClose}
            style={{
                overlay: {
                    backgroundColor: null,
                    outline: "none"
                 
                },
                content: {
                    color: null,
                    outline: "none"
                }
            }}
        >

            <div style={{outline:'none'}} className="stationText container">
                <p className="center-align">.0 {content.number}</p>
                <p className={"modalTitle center-align stationText" + content.number}>{content.name}</p>
                <div className="center-align modalText" dangerouslySetInnerHTML={{ __html: content.modalText }} />
                <p className="center-align modalText spaceBelow">{content.instructions}</p>


                <div className="centeredContent" onClick={closeModal}>  <div className={"button-nav center-align stationColor" + content.number}><p className="buttonText modalButtonText">EXPLORE STATION</p></div></div>
            </div>

        </Modal></div>)


}