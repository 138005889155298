import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import StationContent from "./StationContent.json";



export function LoomDetailPage({ match }) {
    let vh = window.innerHeight * 0.02;
    // Then we set the value in the --vh custom property to the root of the document
    if (window.innerWidth < 600 && window.innerHeight < 700) {
        vh = window.innerHeight * 0.05;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    if (window.innerWidth < 600 && window.innerHeight > 700) {
        vh = window.innerHeight * 0.03;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    console.log(match)
    console.log("Setting VH to ", vh)
    vh = `w:${window.innerWidth} h:${window.innerHeight}`
    let route = window.location.href.split("/")[3]

    let nextExhibitNav = ""

    //TODO REFACTOR MAKING BUTTON INTO ITS OWN MODULE
    if (match.url === "/humansasmachines/jacquardloom") {
        nextExhibitNav = <NavLink to='/humansautomatingmachines'>
            <div className="centeredContent ">
                <div style={{ marginTop: "30px" }} className="button-nav center-align"><p className="navButtonText buttonText">GO TO NEXT STATION</p></div>
            </div>
        </NavLink>

    }

    if (match.url === "/humansautomatingmachines/punchcardmachine") {
        nextExhibitNav = <NavLink to='/machinesashumans'>
            <div className="centeredContent">
                <div style={{ marginTop: "30px" }} className="button-nav center-align"><p className="navButtonText buttonText">GO TO NEXT STATION</p></div>
            </div>
        </NavLink>

    }

    if (match.url === "/humansautomatingmachines/punchcards") {

        nextExhibitNav = <Link to='/punchcard'>
            <div className="centeredContent">
                <div style={{ marginTop: "30px" }} className="button-nav center-align longButton"><p className="navButtonText buttonText" >WEAVE YOUR PUNCHCARD</p></div>
            </div>
        </Link>


    }



    let loomContent = <div></div>
    // Main loop for generating html for Loom Details 
    try {
        const loom = StationContent[route].loom_data.find(({ id }) => id === match.params.topicId)


        let content = loom["content"]
        console.log("Content is : ", content)
        let paragraphContent = [];
         // eslint-disable-next-line
        content.map((paragraph, index) => {
            let newParagraph = []
            let artworkImageUrl = `/images/${loom["name"] + index}.jpg`

            // THIS IS NOT THE WAY TO DO IT BUT WE NEED A REALLY QUICK FIX 
            if (artworkImageUrl === "/images/Punch Card Machine3.jpg") {
                console.log("Changing out for new url ", artworkImageUrl)
                artworkImageUrl = "/images/WeavingHands4.gif"
            }
            // TODO this is not a good way to do this, this needs to be refactored so you just give the image path in the Station Content Data 
            console.log("img ", `/images/${loom["name"] + index}.jpg`)
            newParagraph.push(<div>
                <div className="centeredContent"><p className="loomContentContainer"><div dangerouslySetInnerHTML={{ __html: paragraph.text }} /></p>
                </div>
            </div>)

            if (paragraph.photo_description !== "") {

                newParagraph.push(<div className="centeredContent"> <img className="exhibitImg" src={artworkImageUrl} alt="Logo" ></img> </div>)
                newParagraph.push(<div className="centeredContent"> <p className="loomDetailPhotoDescription" > <div dangerouslySetInnerHTML={{ __html: paragraph.photo_description }} /></p></div>
                )
            }


            paragraphContent.push(newParagraph)
        })


        loomContent = <div className="loomContainer"> {paragraphContent}
            {nextExhibitNav}


        </div>



    } catch (exception) {
        console.log(exception)
    }









    return (


        <div className="loomContent">
            {/* <model-viewer reveal="auto" camera-orbit=" calc(0deg + env(window-scroll-y) * 180deg) calc(3m - env(window-scroll-y) * 1.5m)" loading="eager" id="toggle-model" src={"1209_Backstrap_1K.glb"} environment-image="/machine_shop_02_1k.hdr" shadow-intensity="2" shadow-softness="1" exposure="1.2" alt="A 3D model of an astronaut" camera-controls camera-orbit="6.037e-15deg 75deg 60.58m" min-camera-orbit="auto auto auto" max-camera-orbit="auto auto auto"></model-viewer> */}

            {loomContent}


        </div>
    )
}