import React from 'react';
import { Link, Route, useLocation } from "react-router-dom";
import loom5 from "./assets/1124_jac_1K.glb";
import loom7 from "./assets/1124_Punchcard_1K.glb";
import loom6 from "./assets/punchcard-solo.glb";
import { ExhibitModal } from './ExhibitModal';
import { ExhibitNav } from "./ExhibitNav";
import { LoomDetailPage } from './LoomDetailPage.js';
import machinesAsHumans from './machinesAsHumans';
import { NavBar } from './NavBar';
import { Punchcard } from './punchard.js';
import StationContent from "./StationContent.json";

export default function Looms({ match }) {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      
    let stationLooms = [loom5, loom6, loom7]
    let focusState = ""
    let detailPage = 0
    let locationPage = ""
    let loomName = ""
    let animationType = "simpleDisplay"
    let currentModel = loom5


    const loom_data = StationContent["humansasmachines"].loom_data
    let loomModelUrl = loom5
    let location = useLocation();

    try {
        if (location.state.movementDirection !== undefined) {
            animationType = location.state.movementDirection
        }


        if (location.state.currentFocusedLoom !== undefined) {

            detailPage = location.state.currentFocusedLoom
            loomName = loom_data[detailPage].name
            currentModel = stationLooms[detailPage]

            console.log("The data i got from clear is ", location.state.currentFocusedLoom)
        }
    } catch (e) {
        console.log(e)
    }

    const [animationName, setAnimationName] = React.useState(animationType);

    const [modelOpen, setModelOpen] = React.useState(false);

    function handleChange(newValue) {
        setAnimationName(newValue);
    }
    function handleModelChange(newValue) {
        setModelOpen(newValue);
    }


    function reopenModal() {
        handleModelChange(true)

    }
    let loomUrlName = ""
    let flexContainerName = "stationFlexContainer"

    // Hide Navigation Elements while Focused on Loom Detail Page
    if (window.location.href.split("/").length === 5) {
        loomUrlName = window.location.href.split("/")[4]
        let loom = StationContent["humansautomatingmachines"].loom_data.find(({ id }) => id === loomUrlName)
        console.log("got loom", loom)
        detailPage = loom.index
        locationPage = 2
        loomName = loom.name
        focusState = "navHide"
        loomModelUrl = stationLooms[loom.index]
        console.log("looom URL is ", loomModelUrl)
        flexContainerName = "loomDetailFlexContainer"

        console.log("New page url ", loomName)
    }



    console.log("Automating Moving : ", animationName)
    return (

        <div>
            <div className={flexContainerName}>
                <NavBar loomUrlName={loomUrlName} value={"simpleDisplay"} onChange={handleChange} detailPage={detailPage} loomName={loomName} url={match} detailPageOpen={locationPage} name="humansautomatingmachines"></NavBar>

                <div className={"center-align stationTitleContainer " + focusState} >
                    <div className="stationTitleText">
                        <p className={"exhibitHeader "}>.02 Humans Automating Machines</p>
                        <img className="navLeft buttonText" onClick={reopenModal} src="/svg/info2.svg" alt="AR" />
                    </div>
                </div>
                <model-viewer id="AR-model" ar loading="lazy" src="1124_jac_1K.glb" ios-src="1124_jac_1K.usdz" alt=""></model-viewer>
                <model-viewer reveal="auto" loading="eager" id="toggle-model" src={currentModel} alt="A 3D model" environment-image="/machine_shop_02_1k.hdr" shadow-intensity="2" shadow-softness="1" exposure="1" camera-controls camera-orbit="6.037e-15deg 75deg 60.58m" min-camera-orbit="auto auto auto" max-camera-orbit="auto auto auto"></model-viewer>
                <div className={"navElements " + focusState}>
                    <div className="centeredContent">
                        <ExhibitNav detailPage={detailPage} loom_data={{}} currentUrl={match.url} name="humansautomatingmachines" ></ExhibitNav>
                    </div>
                </div>
                <ExhibitModal modelIsOpen={modelOpen} onChange={handleModelChange} showModal={(focusState !== "navHide")} name="humansautomatingmachines" ></ExhibitModal>

                <div className={"exhibitFooter " +   focusState}>

                    <div className="centerVertical headerText">
                        <Link to={{
                            pathname: '/machinesashumans',
                            state: { movementDirection: 'animateUp' }
                        }}>
                            <img src="/svg/arrow-big-brown.svg" className="arrowDown" alt="Kiwi standing on oval" />
                        </Link>
                    </div>
                </div>


                <Route path={`${match.path}/:topicId`} component={LoomDetailPage} />
                <Route exact path='/punchcard' component={Punchcard} />
                <Route exact path='/machinesAsHumans' component={machinesAsHumans} />
            </div> </div>



    )
}
