import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import HumansAsMachines from './HumansAsMachines';
import { NavBar } from './NavBar';



export default function About() {



  return (

 
    <div>
      <NavBar loomName={""} url={""} detailPageOpen={""} name="about"></NavBar>

      <div className="aboutContainer container">

        <br></br>

        <div className="centeredContent">
          <p className="center-align aboutPageDescription">
          Welcome to “Warping the Future”, a digital exhibition that explores the connection between the history of weaving and the history of computing. This experience is part of an ongoing exploration on demystifying the complexity of both practices by honoring the tools, the innovations and the people behind them. Because of the extreme perishability or the intentional erasure that is present in much of women’s work, the narrative of this exhibition focuses on recognizing their decisive role in this story. 

            </p>
      

        </div>
        <div className="centeredContent">

        <p className="center-align aboutPageDescription">
          
          The exhibition is divided into three stations, and some of the content is available on augmented reality when using a phone or a tablet.
           
          
                      </p>
                      </div>


        <div className="aboutInfoContent">
          <div className="desktopAboutDescription">
          <img  src="/svg/arrows-stations.svg" alt="AR" />

          <p className="navLeft" >Use the up and down brown arrows to navigate between stations;</p>
          </div>

          <div className="desktopAboutDescription">
          <img  src="/svg/arrows-objects.svg" alt="AR" />

          <p className="navLeft" >Use the left and right blue arrows to navigate between the 3D objects;</p>
          </div>
          <div className="desktopAboutDescription">
          <img  src="/svg/ar.svg" alt="AR" />

          <p className="navLeft" >Click on the button with the AR icon to activate objects in augmented reality;</p>
          </div>

          <div className="desktopAboutDescription">
          <img  src="/svg/bookmark-instructions.svg" alt="AR" />

          <p className="navLeft">Click on the bookmark icon to save some content for later. Your saved items list can be found and shared through the “Saved for Later” page.</p>
          </div>
        
        
        </div>
   

        <div className="centeredContent desktopAboutDescription">

        </div>



        <div className="centeredContent">

          <NavLink to={{
            pathname: '/humansasmachines',
            state: { movementDirection: 'animateLeft' }
          }}>  <div className="landingButton aboutPageButton center-align"><p className="buttonText">START EXPERIENCE</p></div></NavLink>


        </div>


        <Route path='/humansasmachines' component={HumansAsMachines} />

      </div></div>
  );
}