/* eslint-disable no-debugger, no-console */

import React, { useState } from 'react';
import Modal from 'react-modal';
import { Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export function NavBar(props) {
  const CloseButton = ({ closeToast }) => (
    <img src="/svg/close/big.svg" className="bookmarkClose"
      onClick={closeToast} alt="X button" />

  );
  console.log("Nav bar props", props)
  let title = props.name.charAt(0).toUpperCase() + props.name.slice(1)



  const [menuState, setMenuState] = useState(false)
  let bookmarks = JSON.parse(localStorage.getItem('bookmarks'));

  let clearIcon = <img className="buttonText" src="/svg/close-light.svg" alt="Clear Current Location" />

  let bookmark = {
    name: props.loomName,
    station: props.name,
    contentId: props.detailPage
  }
  let bookmarkActive = false;

  if (bookmarks !== null) {


    // eslint-disable-next-line
    bookmarks.map((item, index) => {
      if (item.name === bookmark.name) {
        bookmarkActive = true
      }
    });
  }
  let bookmakredState = bookmarkActive ? "edgrey" : ""

  function updateMenuState() {
    let newMenuState = !menuState;
    setMenuState(newMenuState)
  }

  function updateBookmarkState() {

    let updateMessage = ""
    let bookmark = {
      name: props.loomName,
      station: props.name,
      contentId: props.loomUrlName
    }
    let repeatURL;
    let bookmarks = [];

    if (localStorage.getItem('bookmarks') === null) {
      repeatURL = false;

    } else {


      bookmarks = JSON.parse(localStorage.getItem('bookmarks'));
      // eslint-disable-next-line
      bookmarks.map((item, index) => {
        if (item.name === bookmark.name) {
          bookmarks = bookmarks.filter(item => item.name !== bookmark.name)
          localStorage.setItem('bookmarks', JSON.stringify(bookmarks));

          repeatURL = true;
          bookmakredState = ""
          document.getElementById("book").src = window.location.origin + "/svg/bookmark.svg"
          updateMessage = "Removed item from list. "


        }


        else {
          repeatURL = false;
        }
      });

    }


    if (!repeatURL) {

      updateMessage = "Saved item to list."

      bookmarks.push(bookmark);
      localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
      document.getElementById("book").src = window.location.origin + "/svg/bookmarkedgrey.svg"
      bookmakredState = "edgrey"

    }


    toast.warn(updateMessage, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }




  function updateVar() {
    props.onChange("animateD");

  }

  let navBarDisplayElement = ""
  let navWrapperClass = "nav-wrapper"
  let navColor = ("stationColor" + props.detailPageOpen)
  let navIcon = <div rel="noopener noreferrer" onClick={updateMenuState}><i className="material-icons">menu</i></div>
  let leftNavIcon = <Link to="/"><img src="/svg/icon/short.svg" alt="Warping the future home icon" /></Link>

  if (props.name === "humansautomatingmachines") {

    navBarDisplayElement = <div className="centerVertical headerText">
      <Link to={{
        pathname: '/humansasmachines',
        state: { movementDirection: 'animateDown' }
      }}>
        <img onClick={() => updateVar()} src="/svg/arrow-big-brown.svg" className="arrowUp" alt="Kiwi standing on oval" />
      </Link>
    </div>
  } else

    if (props.name === "machinesashumans") {

      navBarDisplayElement = <div className="centerVertical headerText">
        <Link to={{
          pathname: '/humansautomatingmachines',
          state: { movementDirection: 'animateDown' }
        }}>
          <img onClick={() => updateVar()} src="/svg/arrow-big-brown.svg" className="arrowUp" alt="Kiwi standing on oval" />
        </Link>

      </div>
    } else if (props.name === "Punchcard") {
      navBarDisplayElement = <div className="centerVertical headerText nav-link">
        <p className="punchNavText">Click on the circles to punch the holes and create your own pattern for this punch card.</p>
      </div>

      leftNavIcon = <img id="book" onClick={() => updateBookmarkState()} src={"/svg/bookmark" + bookmakredState + ".svg"} alt="Kiwi standing on oval" />
      navIcon = <Link to={`/punchcard`}><i className="material-icons">clear</i></Link>


    } else if (props.name === "Weaving Pattern") {
      navBarDisplayElement = <div className="centerVertical headerText nav-link">
        <p>{title + "..."}</p>
      </div>

      leftNavIcon = <img id="book" onClick={() => updateBookmarkState()} src={"/svg/bookmark" + bookmakredState + ".svg"} alt="Kiwi standing on oval" />
      navIcon = <Link to={`/punchcard`}><i className="material-icons">clear</i></Link>

    } else if (props.name !== "humansasmachines") {

      navBarDisplayElement = <div className="centerVertical headerText nav-link">
        <p>{title}</p>
      </div>
    } else if (props.name === "humansasmachines") {
      navBarDisplayElement = <div className="centerText">
        <p style={{ color: "black" }}>Exhibition</p>
      </div>
    }


  if (navColor !== "stationColor" && (props.name !== "punchcard")) {
    navWrapperClass = "nav-wrapper-loom"
    console.log("The possible props to pass through a link are ", props)
    leftNavIcon = <img id="book" onClick={() => updateBookmarkState()} src={"/svg/bookmark" + bookmakredState + ".svg"} alt="Kiwi standing on oval" />
    navIcon =

      <Link to={{
        pathname: props.url.path,
        state: { currentFocusedLoom: props.detailPage }
      }}>

        {clearIcon}</Link>
    navBarDisplayElement = <p className="centerText">{props.loomName}</p>


  }

  return (<div className={navWrapperClass + ' no-shadows ' + navColor}>

    <div className="centerVertical">{leftNavIcon}</div>
    <div></div>
    <div className="centerVertical headerText"> {navBarDisplayElement}</div>


    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover={false}
      closeButton={CloseButton}
    />    <div className="menu-button centerVertical navRight">{navIcon}</div>





    <Modal
      closeTimeoutMS={1000}

      isOpen={menuState}
      contentLabel="Minimal Modal Example"
      className="MainModal"
      style={{
        overlay: {
          backgroundColor: null
        },
        content: {
          color: null
        }
      }}
    >

      <div className='nav-wrapper no-shadows menuNav'>
        <div className="centerVertical navLeft">{leftNavIcon}</div>
        <div className="centerVertical headerText menuNav"> Warping The Future</div>




        <div className="menu-button centerVertical navRight"><div onClick={updateMenuState} className="menu-button"><i className="material-icons">clear</i></div></div>
      </div>
      <div className="menu-overlay">

        <div className="full-menu--middle">

          <ul className="main-nav">
            <li className="item"><Link to={{
              pathname: '/',
              state: { movementDirection: 'simpleDisplay' }
            }} className="nav-link">Home</Link></li>

            <li className="item"><Link to={{
              pathname: '/about',
              state: { movementDirection: 'simpleDisplay' }
            }} className="nav-link">About</Link></li>
            <li className="item exhibitLink"><Link to={{
              pathname: '/humansasmachines',
              state: { movementDirection: 'simpleDisplay' }
            }} className="nav-link">
              .01 Humans As Machines
              </Link></li>
            <li className="item exhibitLink"><Link to={{
              pathname: '/humansautomatingmachines',
              state: { movementDirection: 'simpleDisplay' }
            }} className="nav-link">.02 Humans Automating Machines </Link></li>
            <li className="item exhibitLink"><Link to={{
              pathname: '/machinesashumans',
              state: { movementDirection: 'simpleDisplay' }
            }} className="nav-link">.03 Machines As Humans </Link></li>
            <li className="item"><Link to={{
              pathname: '/savedforlater',
              state: { movementDirection: 'simpleDisplay' }
            }}>Saved for Later</Link></li>
            <li className="item"><a className="menuIconContainer" rel="noopener noreferrer" target="_blank" href="https://library.tc.columbia.edu/p/warpingthefuture">Book Collection     <img className="bookCollectionArrow" src="/svg/external link.svg" alt="arrow" /></a></li>
            <li className="item"><Link to={{
              pathname: '/credits',
              state: { movementDirection: 'simpleDisplay' }
            }} >Credits</Link></li>
          </ul>
        </div>
      </div>

      <div className="exhibitFooter menuNav">

        <div className="centerVertical headerText menuNav">
        </div>
      </div>
    </Modal>



  </div>)

}