import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import About from './About';



export default function Home() {



  return (


    <div>
      <div>
        <div className="introContainer">

          <p className="center-align introDetailText">The Gottesman Libraries at Teachers College presents</p>



          <div className="centeredContent">
            <img src="/images/logo.svg" className="center-align  heroImage" alt="Kiwi standing on oval" />
          </div>
          <div className="centeredContent">  <p className="wrapText">How craft led to the digital world as we know it</p>  </div>

          <div className="centeredContent">

            <NavLink to={{
              pathname: '/about',
              state: { movementDirection: 'animateLeft' }
            }}> <div className="landingButton searchButtonActive center-align"><p className="buttonText">ABOUT EXHIBITION</p></div></NavLink>


          </div>

          <Route exact path='/about' component={About} />
        </div>
      </div>  </div>
  );
}
