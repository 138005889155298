import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import loom from "./assets/1124_Backstrap_1K.glb";
import loom4 from "./assets/1124_foot_1K.glb";
import loom5 from "./assets/1124_jac_1K.glb";
import loom3 from "./assets/1124_tapestry_2K.glb";
import loom2 from "./assets/1124_WarpWeighted_1K.glb";
// REFACTOR THIS INTO ITS OWN MODULE FOR REUSE
import loom1 from "./assets/1209_Backstrap_1K.glb";
import { ExhibitModal } from './ExhibitModal';
import { ExhibitNav } from './ExhibitNav';
import humansAutomatingMachines from './humansAutomatingMachines';
import { LoomDetailPage } from './LoomDetailPage';
import { NavBar } from './NavBar';
import StationContent from "./StationContent.json";

const stationLooms = [loom1, loom2, loom3, loom4, loom5]

export default function Looms({ match }) {


  let focusState = ""
  let detailPage = 0
  let locationPage = ""
  let loomName = ""

      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);


  const [modalOpen, setModalOpen] = useState(false)
  // const [modelOpen, setModelOpen] = React.useState(false);



  const loom_data = StationContent["humansasmachines"].loom_data
  let loomModelUrl = loom



 
  function handleModelChange(newValue) {
    setModalOpen(newValue);
  }
  let loomUrlName = ""

  let flexContainerName = "stationFlexContainer"


  if (window.location.href.split("/").length === 5) {
    loomUrlName = window.location.href.split("/")[4]
    let loom = StationContent["humansasmachines"].loom_data.find(({ id }) => id === loomUrlName)
    console.log("got loom", loom)
    detailPage = loom.index
    locationPage = 1
    loomName = loom.name
    focusState = "navHide"
    loomModelUrl = stationLooms[loom.index]
    console.log("looom URL is ", loomModelUrl)

    console.log("New page url ", loomName)

    flexContainerName = "loomDetailFlexContainer"
  }



  function reopenModal() {
    handleModelChange(true)
  }


  return (

    <div>
      <div id="stationContain" className={flexContainerName}>
        <NavBar loomUrlName={loomUrlName} value={""} onChange={""} loomName={loomName} detailPage={detailPage} url={match} detailPageOpen={locationPage} name="humansasmachines"></NavBar>

        <div className={"center-align stationTitleContainer " + focusState} >
          <p className={"centeredContent exhibitHeader " + focusState}>.01 Humans As Machines<img className="navLeft buttonText" onClick={reopenModal} src="/svg/info1.svg" alt="AR" /></p>
        </div>
        <model-viewer id="AR-model" ar loading="lazy" src={"1209_Backstrap_1K.glb"} ios-src="1209_Backstrap_1K.usdz" alt="Backstrap Loom"></model-viewer>
        <model-viewer reveal="auto"  loading="eager" id="toggle-model" src={"1209_Backstrap_1K.glb"} environment-image="/machine_shop_02_1k.hdr" shadow-intensity="2" shadow-softness="1" exposure="1.2" alt="A 3D model of an astronaut" camera-controls camera-orbit="6.037e-15deg 75deg 60.58m" min-camera-orbit="auto auto auto" max-camera-orbit="auto auto auto"></model-viewer>

        <div className={"navElements " + focusState}>
          <div className="centeredContent">
            <ExhibitNav detailPage={detailPage} loom_data={loom_data} currentUrl={match.url} name="humansasmachines"></ExhibitNav>
          </div>
        </div>
        <ExhibitModal modelIsOpen={modalOpen} onChange={handleModelChange} showModal={(focusState !== "navHide")} reOpenModal={modalOpen} name="humansasmachines" ></ExhibitModal>
        <div className={"exhibitFooter " +   focusState}>
          <div className="centerVertical headerText">
            <Link to={{
              pathname: '/humansautomatingmachines',
              state: { movementDirection: 'animateUp' }
            }}>
              <img onClick={""} src="/svg/arrow-big-brown.svg" className="arrowDown" alt="Kiwi standing on oval" />
            </Link>
          </div>
        </div>
        <Route path={`${match.path}/:topicId`} component={LoomDetailPage} />
        <Route exact path='/about' component={humansAutomatingMachines} />
      </div>   </div>

  )
}
