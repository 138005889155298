import React, { useState } from 'react';
import { Link, Route, useLocation } from "react-router-dom";
import Select from 'react-select';
import { ExhibitModal } from './ExhibitModal';
import { NavBar } from './NavBar';
import StationContent from "./StationContent.json";
function ProjectContent({ match }) {


    const proj_content = StationContent["machinesashumans"].project_data[match.params.topicId]
    let blurbContent = []
    let mediaContent = proj_content["Media"]
    let embedContent;

    for (var paragraph of proj_content["Blurb"]) {
        console.log(paragraph)

        blurbContent.push(<div class="col s6"><p><span className="">{paragraph}</span></p></div>)
    }

    embedContent = <embed className="machinesAsHumansImage" src={proj_content["Media"]} />


    if (mediaContent.includes("youtube")) {
        embedContent = <embed className="embedContent" src={proj_content["Media"]} />

    }

    if (mediaContent.includes("vimeo")) {
        console.log()
        let width = 375
        let height = 210

        if (window.innerWidth < 375) {
            width = 320
            height = 180
        }

        if (window.innerWidth > 900) {
            width = "50%"
            height = 400
        }


        if (window.innerWidth > 1400) {
            width = "50%"
            height = 500
        }


        embedContent = <iframe title="Vimeo Embed" src={mediaContent} width={width} height={height} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    }




    console.log(mediaContent)

    





    return (
        <div className="machinesAsHumans">
            <div class="container loomContent">
                {embedContent}
                <div class="loomContainer">
                    <p className="station3AuthorText">By {proj_content["Artist(s)"]} </p>
                    {blurbContent}
                    <div class="col s6"><p>For more information click <a rel="noopener noreferrer" target="_blank" href={proj_content["Link"]} className="externalLink">here</a>.</p></div>
                </div>
            </div>
        </div>
    )
}


const autocomplete_options_tree = {
    "loom": {
        "challenges": {
            "textile production": "doti"
        },
        "repurposes": {
            "materials": "postapocalypticloom"
        }
    },
    "performance": {
        "repurposes": {
            "weaving": "bodywrap"
        }
    },
    "software": {
        "visualizes": {
            "sound patterns": "algorithmicpattern"
        },

    },
    "material": {   
        "challenges": {
            "production methods": "exercisesinrootsystemdomestication"
        }
    },
    "textile": {
        "challenges": {
            "traditional patterns": "traditioninpixel"
        },
        "celebrates": {
            "ancestry": "blackhairflag"
        },
        "visualizes": {
            "data": "aquirednationality"
        },
    },
    "installation": {
        "displays": {
            "uncertain patterns": "computer10",
        },
        "amplifies": {
            "textiles": "aliasedquarry",
            "human presence": "theamplifiermachine"

        },
        "creates": {
            "patterns dynamically": "onframingtextileambiguities"
        },
    }
}


export default function Looms({ match }) {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);


    function formatSelectOption(values) {
        let output = []
        for (var i of values) {
            output.push({
                "value": i,
                "label": i
            })
        }

        return output


    }
    let initial_options = formatSelectOption(Object.keys(autocomplete_options_tree))

    // eslint-disable-next-line
    const [dropDownOptions, setDropDownOptions] = useState(initial_options)

    const [option1, setOption1] = useState("")
    const [option2, setOption2] = useState("")

    const [dropDownTwoOptions, setDropDownTwoOptions] = useState([])

    const [dropDownThreeOptions, setDropDownThreeOptions] = useState([])

    const [searchResultsUrl, setSearchResultsUrl] = useState("")

    const [searchButtonState, setSearchButtonState] = useState("")


    function changeDropdownOne(event) {

        let newValue = event.value
        setOption1(newValue)

        let secondaryValues = autocomplete_options_tree[newValue]
        setDropDownTwoOptions(formatSelectOption(Object.keys(secondaryValues)))
        document.getElementById("select2").classList.remove("selectDisabled")

    }

    function changeDropdownTwo(event) {

        let newValue = event.value
        console.log("New value for two ", newValue)
        setOption2(newValue)


        let thirdValues = autocomplete_options_tree[option1][newValue]
        setDropDownThreeOptions(formatSelectOption(Object.keys(thirdValues)))

        document.getElementById("select3").classList.remove("selectDisabled")


    }

    function changeDropdownThree(event) {

        let newValue = event.value
        setSearchResultsUrl(`${match.url}/${autocomplete_options_tree[option1][option2][newValue]}`)
        setSearchButtonState("Active")

    }

    let selectWidth = "300px"
    let selectHeight = "60px"

    if (window.innerWidth < 500) {
        selectWidth = "90vw"
        selectHeight = "50px"
    }

    const customStyles = {
        container: (provided, state) => ({
            ...provided,

            color: 'black',
            width: selectWidth,

            backgroundColor: "rgb(226,228,224);"
        }),
        //Invisible Container around the option, keep no border
        control: (provided, state) => ({
            ...provided,

            color: 'black',
            border: '0px solid rgb(34, 43,60);',
            borderRadius: '10px',

            backgroundColor: "rgb(226,228,224);"
        }),
        // This is the actual menu option
        option: (provided, state) => ({
            ...provided,

            color: 'rgb(34, 43,60)',
            padding: '13px',
            border: '1px solid rgb(34, 43,60);',
            backgroundColor: "rgb(226,228,224);"
        }),
        // The Styles for the DropDown that opens
        menu: (provided, state) => ({
            ...provided,
            color: 'green',
            border: '3px solid rgb(34, 43,60);',
            borderRadius: '10px',
            padding: 0,
            backgroundColor: "rgb(226,228,224);"
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            border: '3px solid rgb(34, 43,60);',
            width: "270px",
            borderRadius: '10px',
            color: 'orange',
            height: selectHeight,
            padding: 0,
            backgroundColor: "rgb(226,228,224);"
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            // border: '1px solid black',
            display: 'none',
            color: 'orange',
            padding: 5,
            backgroundColor: "rgb(226,228,224);"
        }),



        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const color = "#00009B";
            const paddingLeft = "13px";
            const fontSize = "0.875rem"


            return { ...provided, color, paddingLeft, fontSize, opacity };
        },
        placeholder: (provided, state) => {
            const paddingLeft = "13px";



            return { ...provided, paddingLeft };
        }
    }

    let focusState = ""
    let detailPage = ""
    let locationPage = ""
    let loomName = ""
    let animationType = "simpleDisplay"

    let location = useLocation();

    try {
        if (location.state.movementDirection !== undefined) {
            animationType = location.state.movementDirection
        }

        if (location.state.currentFocusedLoom !== undefined) {
            console.log("The data i got from clear is ", location.state.currentFocusedLoom)
        }

    } catch (e) {
        console.log(e)
    }

    const [value, setValue] = React.useState(animationType);
    const [modelOpen, setModelOpen] = React.useState(false);


    function handleModelChange(newValue) {
        setModelOpen(newValue);
    }

    function handleChange(newValue) {
        setValue(newValue);
    }
    let loomUrlName = ""


    if (window.location.href.split("/").length === 5) {

        loomUrlName = window.location.href.split("/")[4]
        console.log(StationContent["machinesashumans"])
        let loom = StationContent["machinesashumans"]["project_data"][loomUrlName]
        console.log("got loom", loom)

        locationPage = 3
        loomName = loom["Title"]
        focusState = "navHide"
        console.log("LOOM NAME ", loomName)

    } else {
        detailPage = ("")
        focusState = ""
    }


    function reopenModal() {
        handleModelChange(true)

    }

    return (

        <div>
            <NavBar loomUrlName={loomUrlName} value={value} onChange={handleChange} detailPage={detailPage} loomName={loomName} url={match} detailPageOpen={locationPage} name="machinesashumans"></NavBar>

            <div className={"container  " + focusState} >

                <div className="center-align">
                    <p className={"centeredContent exhibitHeader " + focusState}>.03 Machines As Humans
                    <img className="navLeft buttonText" onClick={reopenModal} src="/svg/info3.svg" alt="AR" />
                    </p>
                </div>


                <div className="station3Container">
                    <div className="centeredContent stationText3 station3Title">

                        <p>I want to learn about</p>
                    </div>

                    <div className="selectRow">

                        <div class="dropdown dropDownText">
                            <Select width='500px' height='300px' placeholder="Select Medium" styles={customStyles} onChange={changeDropdownOne} options={dropDownOptions} />

                        </div>
                        <div className="dropDownText selectText">
                            <p className="station3Title fontDarkGrey">that</p>  </div>
                        <div class="dropdown dropDownText selectDisabled" id="select2" >
                            <Select styles={customStyles} placeholder="Select Action" onChange={changeDropdownTwo} options={dropDownTwoOptions} />
                        </div>
                        <div class="dropdown dropDownText selectDisabled" id="select3" >
                            <Select styles={customStyles} placeholder="Select Characteristic" onChange={changeDropdownThree} options={dropDownThreeOptions} />
                        </div>

                    </div>

                    <div className="centeredContent">
                        <div className={"button-nav center-align searchButton" + searchButtonState}><Link className="centeredContent" to={searchResultsUrl}><p className="buttonText"> FIND PROJECT</p></Link></div>
                    </div>

                </div>

                <ExhibitModal modelIsOpen={modelOpen} onChange={handleModelChange} showModal={(focusState !== "navHide")} name="machinesashumans" ></ExhibitModal>
            </div>
            <Route path={`${match.path}/:topicId`} component={ProjectContent} />


        </div>
    )
}

