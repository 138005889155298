import React from "react";
import Modal from 'react-modal';
import { Route } from "react-router-dom";
import About from './About';
import './App.css';
import Credits from './Credits';
import HumansAsMachines from './HumansAsMachines';
import HumansAutomatingMachines from './humansAutomatingMachines';
import Intro from './Intro';
import MachinesAsHumans from './machinesAsHumans';
import { Punchcard } from './punchard';
import SavedForLater from './SavedForLater';



Modal.setAppElement('#root')


function App() {



  return (
    <div>
      <Route path="/about" component={About} />
          <Route exact path="/" component={Intro} />
      <Route path="/humansasmachines" component={HumansAsMachines} />
      <Route path="/humansautomatingmachines" component={HumansAutomatingMachines} />
      <Route path='/machinesashumans' component={MachinesAsHumans} />
      <Route path='/punchcard' component={Punchcard} />
      <Route path='/credits' component={Credits} />
      <Route path='/savedforlater' component={SavedForLater} />
    </div>
  );
}





export default App;
