import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, ToastContainer } from 'react-toastify';
import { NavBar } from './NavBar';
import StationContent from "./StationContent.json";


export default function Looms({ match }) {
    const CloseButton = ({ closeToast }) => (
        <img src="/svg/close/big.svg" className="bookmarkClose"
            onClick={closeToast} alt="X button" />

    );
    let focusState = ""
    let locationPage = ""
    let loomName = ""

    let bookmarks = JSON.parse(localStorage.getItem('bookmarks'));
    console.log("Bookmarks is ", bookmarks)
    bookmarks = bookmarks === null ? [] : bookmarks

    console.log(bookmarks)

    let text = "Some highlights from Warping the Future: "
    bookmarks.map((bookmarkedContent, index) => (
        text += ` https://www.warpingthefuture.online/${bookmarkedContent.station}/${bookmarkedContent.contentId} `


    ))

    console.log("Link to share ", text)


    text += " To experience the full exhibition please visit https://www.warpingthefuture.online."



    let embedHeight = 500
    if (window.innerWidth < 900) {
        embedHeight = 200
    }



    function renderBookmarkedContent(bookmarkedContent, index) {
        console.log("Creating div for bookmark", bookmarkedContent)
        let listItem = ""

        try {


            if (bookmarkedContent.station === "machinesashumans") {
                let loom = StationContent["machinesashumans"]["project_data"][bookmarkedContent.contentId]
           
                listItem = (<div>
                    <div className="centeredContent"> <img className="savedForLaterImg" src={"/images/" + loom["SaveForLaterImage"]} alt="Logo" ></img> </div>



                    <div className="centeredContent"> <a rel="noopener noreferrer" target="_blank" href={`/${bookmarkedContent.station}/${bookmarkedContent.contentId}`} key={index}><p className="savedForLaterLink">{bookmarkedContent.name}</p></a></div>
                </div>)

            } else if (bookmarkedContent.station === "Punchcard") {
                embedHeight = ""

                // if (window.innerWidth < 1500) {
                //     embedHeight = 300
                // }

                // if (window.innerWidth < 1000) {
                //     embedHeight = 200
                // }

                listItem = (<div>
                    <div className="centeredContent">

                        <embed className="savedForLaterImg" src={"/images/saveforlater/DesignYourPunchCards.png"} width={window.innerWidth} height={embedHeight} />
                    </div>


                    <div className="centeredContent"> <a rel="noopener noreferrer" target="_blank" href="punchcard" key={index}><p className="savedForLaterLink">Design Your Punchcard</p></a></div>
                </div>)

            }



            else {
                let loom = StationContent[bookmarkedContent.station].loom_data.find(({ id }) => id === bookmarkedContent.contentId)

                console.log("Photo url ", loom["coverPhoto"])
                listItem = (<div>
                    <div className="centeredContent"> <img className="savedForLaterImg" src={loom["coverPhoto"]} alt="Logo" ></img> </div>


                    <div className="centeredContent"> <a rel="noopener noreferrer" target="_blank" href={`/${bookmarkedContent.station}/${bookmarkedContent.contentId}`} key={index}><p className="savedForLaterLink">{bookmarkedContent.name}</p></a></div>
                </div>)
            }
        } catch (e) {
            console.log(e)
        }
        return (listItem)

    }

    function renderToast() {
        toast.warn("Copied All Items", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined
        });
    }
    console.log("Current match is! ", match)

    return (


        <div>
            <NavBar loomName={loomName} url={match} detailPageOpen={locationPage} name="Saved For Later"></NavBar>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                closeButton={ CloseButton }

            />
            <div className={"container  " + focusState} >

                <div className="savedList">
                    {bookmarks.map((bookmarkedContent, index) => {
                        return <div key={index}> {renderBookmarkedContent(bookmarkedContent, index)}

                        </div>
                    })}

                </div>
                <div className="centeredContent bottomPadding">

                    <CopyToClipboard text={text}
                        onCopy={() => renderToast()}>
                        <div className={"button-nav center-align "}>
                            <div className="centeredContent">
                                <p className="buttonText">SHARE ALL ITEMS</p>
                                <img src="/svg/share.svg" className="shareIcon center-align" alt="Kiwi standing on oval" />

                            </div>
                        </div>

                    </CopyToClipboard>
                </div>
            </div>








        </div>)
}

