import React from 'react';
import Sketch from "react-p5";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


window.addEventListener("orientationchange", function () {
    // announce the new orientation number
    window.location.reload();

}, false);


export function Punchcard(props) {
    let vh;

    const CloseButton = ({ closeToast }) => (
        <img src="/svg/close/big.svg" className="bookmarkClose"
            onClick={closeToast} alt="X button" />

    );

    if (window.innerWidth < 600 && window.innerHeight < 700) {
        vh = window.innerHeight * 0.05;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    if (window.innerWidth < 600 && window.innerHeight > 700) {
        vh = window.innerHeight * 0.06;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    let hideNavFlag = ""

    let notchXValue = 35
    let notchYValue = 155
    let weaveXDrawStart = 0
     // eslint-disable-next-line
    let weaveYDrawStart = 0
    let spacePlaceholder = 90

    let punchcardYValue = 700;
    let cols = 20
    let rows = 8
    let rectSize = 15
    let notchSize = 20
    let colsToDraw = 8
    let rowsToDraw = 10
    let errorMessageState = "hide-nav"

    let punchInstructions = <div><p className="">Click on the circles to punch the holes and create your own
        pattern for this punch card.</p></div>


    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // true for mobile device


        punchInstructions = <div><p className="punchNavText">Click on the circles to punch the holes and</p> <p
            className="punchNavText">create your own pattern for this punch card.</p></div>


        console.log("mobile device !! ");
        console.log("checking device orientation", window.orientation)
        weaveXDrawStart = 0
        weaveYDrawStart = 0
        spacePlaceholder = 0
        rectSize = 12

        notchXValue = 25
        notchYValue = 35
        punchcardYValue = window.innerHeight - 50;
        cols = 20
        rows = 8
        notchSize = 15
        colsToDraw = 4


        if (window.innerWidth < 600) {
            notchXValue = 20
            notchYValue = 15
            notchSize = 13
            punchcardYValue = window.innerHeight - 80;

            rows = 5
            rowsToDraw = rows + 1


            cols = 10
            //   // notchSize = 15
            colsToDraw = 5

        }

        if (window.orientation === 0 || window.innerWidth > 900) {
            console.log("Change")
            hideNavFlag = "hide-nav"
            errorMessageState = ""
            // document.getElementById("errorMessage").classList.remove("hide-nav")

            // document.getElementsByClassName("sketchContainer")[0].classList().add("hide-nav")
            // toast.error("Please Rotate Your Device To Landscape Mode ", {
            //   position: "top-center",
            //   progress: undefined,
            //   autoClose: false

            // });
        }

    } else {
        // false for not mobile device

        if (window.innerWidth < 1700) {
            notchXValue = 35
            notchYValue = 155
            weaveXDrawStart = 0
            weaveYDrawStart = 0
            spacePlaceholder = 90

            punchcardYValue = 600;
            cols = 20
            rows = 6
            rectSize = 15
            notchSize = 16
            colsToDraw = 8
            rowsToDraw = 10
        }
    }





    // p5 sketch global variables
    let settingUpPunchcard = true
    let notch;
    let notches = []
    // eslint-disable-next-line
    let r, g, b;

    let punchCardStructure = []
    let renderPunchcard = false;
    let reinitPunchcard = false;
    // eslint-disable-next-line
    let rectXStart = 0;
    // eslint-disable-next-line
    let rectYStart = 0;
    // eslint-disable-next-line
    let rectXEnd = 0;
    // eslint-disable-next-line
    let rectYEnd = 0;
    let drawingSquare = false;
    // eslint-disable-next-line
    let readyForWeave = false;


    class Notch {
        constructor(x, y, size) {
            this.x = x;
            this.y = y;
            this.size = size;
            this.isClicked = false;
            this.readyForUpdate = true;

        }


    }

    console.log("PROPS", props)

    function checkIfPunchcardPunched() {
        let enabledNotches = []
        for (var x = 0; x < punchCardStructure.length; x++) {
            let currentLine = punchCardStructure[x]
            enabledNotches += currentLine.filter(val => {

                return val.isClicked


            })
        }

        return enabledNotches.length > 0
    }

    function renderPunchcardSketch() {

        if (checkIfPunchcardPunched()) {


            renderPunchcard = !renderPunchcard;
            settingUpPunchcard = !settingUpPunchcard;
        }
    }


    function editPunchCard() {
        document.getElementById("punchNavContainer").classList.add("hide-nav")

        document.getElementById("punchNavInstructions").style.display = "flex"


        renderPunchcard = !renderPunchcard;
        settingUpPunchcard = !settingUpPunchcard;
    }


    function resetPunchcard() {
        reinitPunchcard = true;
        renderPunchcard = false;
        settingUpPunchcard = false;
        document.getElementById("weavePatternButton").classList.add("stationButonDisabled")
        document.getElementById("weavePatternButton").classList.remove("stationColor2")
    }

    let bookmarks = JSON.parse(localStorage.getItem('bookmarks'));

    let bookmark = {
        name: "Weave Your Punchcard",
        station: "Punchcard",
        contentId: "Punchcard"
    }
    let bookmarkActive = false;

    if (bookmarks !== null) {

 // eslint-disable-next-line
        bookmarks.map((item, index) => {
            if (item.name === bookmark.name) {
                bookmarkActive = true
            }
        });
    }
    let bookmakredState = bookmarkActive ? "ed" : ""
    function updateBookmarkState() {

        let updateMessage = ""
        let bookmark = {
            name: "Weave Your Punchcard",
            station: "Punchcard",
            contentId: "Punchcard"
        }
        let repeatURL;
        let bookmarks = [];

        if (localStorage.getItem('bookmarks') === null) {
            repeatURL = false;

        } else {


            bookmarks = JSON.parse(localStorage.getItem('bookmarks'));
            // eslint-disable-next-line
            bookmarks.map((item, index) => {
                if (item.name === bookmark.name) {
                    bookmarks = bookmarks.filter(item => item.name !== bookmark.name)
                    localStorage.setItem('bookmarks', JSON.stringify(bookmarks));

                    repeatURL = true;
                    bookmakredState = ""
                    document.getElementById("book").src = window.location.origin + "/svg/bookmark-pink.svg"
                    updateMessage = "Removed item from list. "


                }


                else {
                    repeatURL = false;
                }
            });

        }


        if (!repeatURL) {

            updateMessage = "Saved item to list."

            bookmarks.push(bookmark);
            localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
            document.getElementById("book").src = window.location.origin + "/svg/bookmarked-pink.svg"
            bookmakredState = "ed"

        }


        toast.warn(updateMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
    }



    return (
        <div>
            <div className="punchCardContainer">


                <div id="punchNavContainer" className={'hide-nav nav-wrapper-punch no-shadows punchNav '}>

                    <div className="centerVertical navLeft">
                        <p className="punchNavText">Your Weaved Pattern</p>

                    </div>


                    <div className="punchcardGroup">

                        <div className="centerVertical">

                            <div onClick={editPunchCard} id=""
                                className={"weaveButton button-nav stationColor2 center-align"}>

                                <p className="buttonText punchButtonText">EDIT PATTERN</p>


                            </div>
                        </div>
                        <div className="menu-button centerVertical navRight">

                            <Link to="/humansautomatingmachines/punchcards"><img src="/svg/close/big.svg" className=""
                                alt="Bookmark Icon" /></Link>
                        </div>


                    </div>
                </div>


                <div id="punchNavInstructions" className={'nav-wrapper-punch no-shadows punchNav ' + hideNavFlag}>
                    <div className="centeredContent">

                        <div className="centerVertical navLeft">
                            <img id="book" onClick={() => updateBookmarkState()} src={"/svg/bookmark" + bookmakredState + "-pink.svg"}
                                alt="Bookmark Icon" />
                        </div>

                        <div className="navLeft centerVertical">
                            {punchInstructions}

                        </div>
                    </div>
                    <div className="centerVertical">

                    </div>

                    <div className="punchcardGroup">
                        <div onClick={resetPunchcard} className="menu-button centerVertical navRight">
                            <img src="/svg/reset.svg" className="resetIcon" alt="Circular Reset Button" />
                        </div>

                        <div className="centerVertical">
                            <div onClick={renderPunchcardSketch} id="weavePatternButton"
                                className={" weaveButton button-nav center-align stationButonDisabled"}>

                                <p className="buttonText punchButtonText">WEAVE PATTERN</p>


                            </div>
                        </div>

                        <div className="menu-button centerVertical navRight">

                            <Link to="/humansautomatingmachines/punchcards"><img src="/svg/close/big.svg" className=""
                                alt="X button" /></Link>
                        </div>


                    </div>
                </div>

                <ToastContainer
                    position="top-center"
                    autoClose={false}

                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    closeButton={CloseButton}

                />


                <div id="errorMessage" className={"container " + errorMessageState}>
                    <div></div>
                    <div className="centeredContent mobileAboutDescription">

                        <img src="/svg/phonesideways.svg" className="" alt="Kiwi standing on oval" />

                    </div>

                    <div className="centeredContent  mobileAboutDescription">
                        <p className="center-align punchcardErrorMessage mobileAboutDescription">To activate experience
                            please rotate device to landscape orientation</p>

                    </div>

                    <div className="centeredContent mobileAboutDescription">


                        <Link to="/humansautomatingmachines/punchcards"
                            className={"errorBrown button-nav center-align"}>
                            <div className="centeredContent">


                                <img src="/svg/arrow-small-lightgrey.svg" className="backArrow" alt="Right Facing Arrow" />

                                <p className="buttonText">BACK TO PUNCH CARDS</p>
                            </div>

                        </Link>
                    </div>
                </div>
                <div className={"sketchContainer " + hideNavFlag}>
                    <Sketch


                        setup={(p5, parent) => {

                            p5.createCanvas(window.innerWidth, window.innerHeight - 50).parent(parent);

                            r = p5.random(255);
                            g = p5.random(255);
                            b = p5.random(255);
                            notch = new Notch(100, 100, notchSize)
                            // p5.background(197, 202, 193);
                            p5.background(34, 43, 60);
                            p5.fill("red")

                            p5.rect(0, 0, 100.100)
                            let currentIndex = 0
                            for (var x = notchXValue; x < p5.width; x += (p5.width / cols)) {

                                punchCardStructure.push([])
                                for (var y = notchYValue; y < punchcardYValue; y += punchcardYValue / rows) {

                                    notch = new Notch(x, y, notchSize)


                                    punchCardStructure[currentIndex].push(notch)
                                    let currentPunchIndex = notch

                                    // punchCardStructure[x][y].display()
                                    // p5.noStroke()

                                    if (currentPunchIndex.isClicked) {
                                        p5.fill(197, 202, 193);
                                    } else {
                                        p5.fill(34, 43, 60)
                                    }
                                    p5.ellipse(currentPunchIndex.x, currentPunchIndex.y, currentPunchIndex.size * 2);
                                    notches.push(notch)

                                }

                                currentIndex++
                            }


                        }}
                        mousePressed={(p5, parent) => {


                            drawingSquare = true;


                        }}


                        mouseReleased={(p5, parent) => {
                            drawingSquare = false;
                            for (var x = 0; x < punchCardStructure.length; x++) {

                                for (var y = 0; y < punchCardStructure[x].length; y++) {

                                    let currentPunchIndex = punchCardStructure[x][y]
                                    currentPunchIndex.readyForUpdate = true


                                }

                            }
                            // eslint-disable-next-line
                            let enabledNotches = []
                            for (x = 0; x < punchCardStructure.length; x++) {
                                let currentLine = punchCardStructure[x]
                                enabledNotches += currentLine.filter(val => {

                                    return val.isClicked


                                })
                            }

                            if (checkIfPunchcardPunched()) {
                                document.getElementById("weavePatternButton").classList.remove("stationButonDisabled")
                                document.getElementById("weavePatternButton").classList.add("stationColor2")

                                readyForWeave = true;
                            } else {
                                document.getElementById("weavePatternButton").classList.add("stationButonDisabled")
                                document.getElementById("weavePatternButton").classList.remove("stationColor2")
                            }

                        }}
                        draw={p5 => {

                            function setupGrid() {
                                p5.strokeWeight(1);
                                p5.stroke(51);
                                p5.background(197, 202, 193)

                                var currentCol = 0
                                for (var currentRow = 0; currentRow <= rowsToDraw; currentRow++) {

                                    var currentX = weaveXDrawStart;


                                    for (currentCol = 0; currentCol <= colsToDraw; currentCol++) {


                                        for (var x = 0; x < punchCardStructure.length; x++) {
                                            let currentY = (currentRow * (rectSize * (rows - 1)));
                                            if (window.innerWidth < 600) {
                                                currentY = (currentRow * (rectSize * (rows)));
                                            }

                                            for (var y = 0; y < punchCardStructure[x].length; y++) {
                                                p5.fill("black")
                                                if (punchCardStructure[x][y].isClicked) {
                                                    p5.fill("black")
                                                } else {
                                                    p5.fill(197, 202, 193)
                                                }


                                                p5.rect(currentX, currentY, rectSize, rectSize);

                                                currentY += rectSize
                                            }

                                            currentX += rectSize

                                        }

                                    }

                                }

                            }

                            function weavePattern() {
                                document.getElementById("punchNavContainer").classList.remove("hide-nav")

                                document.getElementById("punchNavInstructions").style.display = "None"

                                let val = p5.random(255);
                                p5.background(val);
                                settingUpPunchcard = false
                                p5.clear()
                                setupGrid()
                            }


                            if (settingUpPunchcard) {
                                p5.clear()


                                p5.background(34, 43, 60);

                                p5.fill(197, 202, 193)

                                p5.rect(0, 0, p5.width, spacePlaceholder)
                                p5.stroke(197, 202, 193);


                                if (drawingSquare) {
                                    for (var x = 0; x < punchCardStructure.length; x++) {
                                        for (var y = 0; y < punchCardStructure[x].length; y++) {
                                            let currentPunchIndex = punchCardStructure[x][y]
                                            if (p5.dist(p5.mouseX, p5.mouseY, currentPunchIndex.x, currentPunchIndex.y) < currentPunchIndex.size && currentPunchIndex.readyForUpdate) {
                                                currentPunchIndex.isClicked = !currentPunchIndex.isClicked;
                                                currentPunchIndex.readyForUpdate = false
                                            }
                                        }
                                    }
                                }
                                for (x = 0; x < punchCardStructure.length; x++) {

                                    for (y = 0; y < punchCardStructure[x].length; y++) {

                                        let currentPunchIndex = punchCardStructure[x][y]

                                        if (currentPunchIndex.isClicked) {
                                            p5.fill(197, 202, 193);
                                        } else {
                                            p5.fill(34, 43, 60)
                                        }
                                        p5.ellipse(currentPunchIndex.x, currentPunchIndex.y, currentPunchIndex.size * 2);

                                    }

                                }

                            } else if (renderPunchcard) {
                                //Time to weave Pattern
                                weavePattern();

                            } else if (reinitPunchcard) {
                                p5.clear()
                                for (x = 0; x < punchCardStructure.length; x++) {

                                    for (y = 0; y < punchCardStructure[x].length; y++) {

                                        let currentPunchIndex = punchCardStructure[x][y]

                                        currentPunchIndex.isClicked = false


                                    }
                                }

                                settingUpPunchcard = true;
                                reinitPunchcard = false;
                            }


                        }
                        }
                    />
                </div>


            </div>
        </div>

    )

}